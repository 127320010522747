import React from "react"
import cuid from "cuid"
import { Typography, Box } from "@mui/material"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Paper from "@mui/material/Paper"

function createData(name, blue, sliv, mark, luna) {
  return { name, blue, sliv, mark, luna }
}

const rows = [
  createData("Turq (Copper)", "1", "1/10", "1/100", "1/1000"),
  createData("Sliver (Silver)", "10", "1", "1/10", "1/100"),
  createData("Mark (Gold)", "100", "10", "1", "1/10"),
  createData("Luna (Moon Silver)", "1000", "100", "10", "1"),
]
const currencyDetails = [
  {
    id: cuid(),
    title: `Turq (Copper Coin)`,
    description: `A "turq," slang for turquoise, is a small round copper coin.  Most coins have oxidized giving it the blue-green or turquoise color.  The coin's markings are the region's commonly traded goods.`,
  },
  {
    id: cuid(),
    title: `Sliv (Silver Coin)`,
    description: `A sliv is a rectangular-shaped coin.  The rectangular shape will vary according to its region, but the weight of all sliv will always be the same.  The coin's markings are the region's sigil.`,
  },
  {
    id: cuid(),
    title: `Gold Mark`,
    description: `A Gold Mark is a round gold coin.  Politicians, aristocrats, highly ranked military officials, business owners, or royalty are typical owners of Gold Marks.  The coin's markings are the region's royal symbol.`,
  },
  {
    id: cuid(),
    title: `Luna (Moon Silver)`,
    description: `A Luna is a half-moon-shaped coin made of moon silver.  The Luna was the ancient currency of Yrub Silas and minted by the moonsmiths of Sirothel.  They are highly polished and contain the typical blueish lavender hue of moonsilver.  The coin's markings are the royal symbol of Sirothel.`,
  },
]

const Currency = () => {
  return (
    <Box>
      <Typography variant="h4" color="secondary" align="center">
        CURRENCY OF YRUB SILAS
      </Typography>

      <Box mb={8}>
        {currencyDetails.map(({ id, title, description }) => (
          <Box key={id}>
            <Typography variant="h6">{title}</Typography>
            <Typography paragraph color="textSecondary" align="left">
              {description}
            </Typography>
          </Box>
        ))}
      </Box>
      <Box mb={4}>
        <Typography variant="h6" color="secondary" align="center">
          Conversion Chart
        </Typography>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="center"></TableCell>
                <TableCell align="center">Turq (Copper)</TableCell>
                <TableCell align="center">Sliv (Silver)</TableCell>
                <TableCell align="center">Mark (Gold)</TableCell>
                <TableCell align="center">Luna (Moon Silver)</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map(row => (
                <TableRow
                  key={cuid()}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row" align="center">
                    {row.name}
                  </TableCell>
                  <TableCell component="th" scope="row" align="center">
                    {row.blue}
                  </TableCell>
                  <TableCell align="center">{row.sliv}</TableCell>
                  <TableCell align="center">{row.mark}</TableCell>
                  <TableCell align="center">{row.luna}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  )
}

export default Currency

import cuid from "cuid"
const helionne = {
  id: cuid(),
  name: `Helionne`,
  population: `Mainly human, some fey`,
  ecology: {
    mainClimate: `temperate - forested`,
    mountains: `some`,
    ocean: `south and west`,
    frequentTroubles: `fey disasters`,
    wilderness: `90%`,
    wildAnimals: `dangerous`,
    naturalResources: `scarce`,
  },
  politics: {
    politicalStructure: `democracy`,
    strongInfluence: `theocracy`,
    popularIssue: `personal freedoms`,
    personalFreedoms: `very good`,
    scandals: `very rare`,
    foreignRelations: `aligned with Aliak`,
  },
  culture: {
    highlyValues: `trade`,
    knownFor: `Theocracy - Order of the Sun`,
    popularEntertainment: `rival games`,
    respectedProfession: `farmer, blacksmith`,
    discrimination: `virtually none`,
    majorTaboo: `interracial love with the fey`,
    majorSocialIll: `bribery`,
    gastronomy: `deer, rice, corn, carrots, apples, bananas`,
  },
  religion: {
    type: `polytheism`,
    focus: `leader worship`,
    worship: `solemn prayer, mostly on holidays`,
    associatedArtform: `instrumental music`,
    prevelance: `believed by some`,
    holidays: `often`,
  },
  economy: {
    mainExport: `food crops - corn`,
    mainImport: `raw ore`,
    mainResource: `food - crops`,
    trade: `imports and exports equal`,
    strength: `stable`,
    wealth: `concentrated in a farming`,
  },
  military: {
    strength: `weak`,
    focus: `land`,
    mainUnit: `land`,
    specializedUnit: `none`,
    soldiers: `hired mercenaries`,
    rank: `none`,
  },
}

export default helionne

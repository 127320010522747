import React from "react"
import PropTypes from "prop-types"
import Grid from "@mui/material/Grid"
import Tabs from "@mui/material/Tabs"
import Tab from "@mui/material/Tab"
import Box from "@mui/material/Box"

import { Container, Section, HammerBreak } from "../Layout"

import Currency from "./Currency"
import Realms from "./Realms"
import { Timeline } from "../Timeline"

import { TabPanel } from "../TabPanel"

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  }
}

const Mythos = ({ children }) => {
  const [value, setValue] = React.useState(0)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }
  return (
    <Box>
      <Container>
        <Section>
          <Grid container>
            <Grid item xs={12} md={2}>
              <Tabs
                orientation="vertical"
                value={value}
                onChange={handleChange}
                aria-label="Vertical tabs example"
                textColor="secondary"
                indicatorColor="secondary"
                sx={{ borderRight: 1, borderColor: "divider" }}
              >
                <Tab label="Currency" {...a11yProps(0)} />
                <Tab label="Realms" {...a11yProps(1)} />
                <Tab label="Timeline" {...a11yProps(1)} />
              </Tabs>
            </Grid>
            <Grid item xs={12} md={10}>
              <TabPanel value={value} index={0}>
                <Currency />
              </TabPanel>
              <TabPanel value={value} index={1}>
                <Realms />
              </TabPanel>
              <TabPanel value={value} index={2}>
                <Timeline />
              </TabPanel>
            </Grid>
          </Grid>
        </Section>
      </Container>
    </Box>
  )
}

Mythos.propTypes = {
  children: PropTypes.node,
}

export default Mythos

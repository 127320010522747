import cuid from "cuid"
const rootmoor = {
  id: cuid(),
  name: `Rotmoor`,
  population: `Primarily Frogfolk and Lizardfolk`,
  ecology: {
    mainClimate: `Tropical - swamp`,
    mountains: `minimal`,
    ocean: `none`,
    frequentTroubles: `hurricanes`,
    wilderness: `90%`,
    wildAnimals: `dangerous`,
    naturalResources: `average`,
  },
  politics: {
    politicalStructure: `tribes - waring`,
    strongInfluence: `tribal shamans`,
    popularIssue: `ethics`,
    personalFreedoms: `very bad`,
    scandals: `frequent`,
    foreignRelations: `bad`,
  },
  culture: {
    highlyValues: `tribal power`,
    knownFor: `poison`,
    popularEntertainment: `none`,
    respectedProfession: `tribal warrior`,
    discrimination: `race-based`,
    majorTaboo: `certain religious rituals`,
    majorSocialIll: `disloyal`,
    gastronomy: `Insects, Avocados, Plantains, Iguana, Wild Boar, Cacao, Python, Deer, Squash, Chili peppers, Corn`,
  },
  religion: {
    type: `monotheism`,
    focus: `sin`,
    worship: `solumn group prayer`,
    associatedArtform: `painting/murals`,
    prevelance: `believed by almost all`,
    holidays: `not often`,
  },
  economy: {
    mainExport: `food crops`,
    mainImport: `textiles`,
    mainResource: `food - crops`,
    trade: `imports and exports equal`,
    strength: `stable`,
    wealth: `tribal controlled`,
  },
  military: {
    strength: `somewhat strong`,
    focus: `land`,
    mainUnit: `foot soldiers`,
    specializedUnit: ``,
    soldiers: `volunteers`,
    rank: ``,
  },
}

export default rootmoor

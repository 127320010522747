import React from "react"
import cuid from "cuid"
import { Grid, Box, Typography } from "@mui/material"
import { Scrollchor } from "react-scrollchor"
import _ from "lodash"

import { HammerBreak } from "../Layout"

import realmData from "./realmData"

const Realms = () => {
  const realmLinks = realmData.map(el => {
    const realmObj = { id: cuid(), name: el.name }
    return realmObj
  })
  return (
    <Box>
      <Box display="flex">
        {realmLinks.map(({ id, name }) => (
          <Box m={1}>
            <Scrollchor
              to={`#${_.lowerCase(name)}`}
              className="nav-link"
              key={id}
            >
              {name}
            </Scrollchor>
          </Box>
        ))}
      </Box>
      <HammerBreak />
      <Box>
        {realmData.map(
          ({
            id,
            name,
            population,
            ecology,
            politics,
            culture,
            religion,
            economy,
            military,
          }) => (
            <Box id={`${_.lowerCase(name)}`}>
              <Box mb={2}>
                <Typography variant="h3">{name}</Typography>
                <Typography color="textSecondary">{`Population: ${population}`}</Typography>
              </Box>
              <Grid container key={id}>
                <Grid item xs={12} md={6}>
                  <Box mb={2}>
                    <Typography variant="h6">Ecology</Typography>
                    <Typography color="textSecondary">{`Main Climate: ${ecology.mainClimate}`}</Typography>
                    <Typography color="textSecondary">{`Mountains: ${ecology.mountains}`}</Typography>
                    <Typography color="textSecondary">{`Ocean: ${ecology.ocean}`}</Typography>
                    <Typography color="textSecondary">{`Frequent Troubles: ${ecology.frequentTroubles}`}</Typography>
                    <Typography color="textSecondary">{`Wilderness: ${ecology.wilderness}`}</Typography>
                    <Typography color="textSecondary">{`Natural Resources: ${ecology.naturalResources}`}</Typography>
                  </Box>
                  <Box mb={2}>
                    <Typography variant="h6">Politics</Typography>
                    <Typography color="textSecondary">{`Political Structure: ${politics.politicalStructure}`}</Typography>
                    <Typography color="textSecondary">{`Strong Influence: ${politics.strongInfluence}`}</Typography>
                    <Typography color="textSecondary">{`Popular Issue: ${politics.popularIssue}`}</Typography>
                    <Typography color="textSecondary">{`Personal Freedoms: ${politics.personalFreedoms}`}</Typography>
                    <Typography color="textSecondary">{`Scandals: ${politics.scandals}`}</Typography>
                    <Typography color="textSecondary">{`Foreign Relations: ${politics.foreignRelations}`}</Typography>
                  </Box>
                  <Box mb={2}>
                    <Typography variant="h6">Culture</Typography>
                    <Typography color="textSecondary">{`Highly Values: ${culture.highlyValues}`}</Typography>
                    <Typography color="textSecondary">{`Known For: ${culture.knownFor}`}</Typography>
                    <Typography color="textSecondary">{`Popular Entertainment: ${culture.popularEntertainment}`}</Typography>
                    <Typography color="textSecondary">{`Respected Profession: ${culture.respectedProfession}`}</Typography>
                    <Typography color="textSecondary">{`Discrimination: ${culture.discrimination}`}</Typography>
                    <Typography color="textSecondary">{`Major Taboo: ${culture.majorTaboo}`}</Typography>
                    <Typography color="textSecondary">{`Major Social Ill: ${culture.majorSocialIll}`}</Typography>
                    <Typography color="textSecondary">{`Gastronomy: ${culture.gastronomy}`}</Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box mb={2}>
                    <Typography variant="h6">Religion</Typography>
                    <Typography color="textSecondary">{`Type: ${religion.type}`}</Typography>
                    <Typography color="textSecondary">{`Focus: ${religion.focus}`}</Typography>
                    <Typography color="textSecondary">{`Worship: ${religion.worship}`}</Typography>
                    <Typography color="textSecondary">{`Associated Art Form: ${religion.associatedArtForm}`}</Typography>
                    <Typography color="textSecondary">{`Prevelance: ${religion.prevelance}`}</Typography>
                    <Typography color="textSecondary">{`Holidays: ${religion.holidays}`}</Typography>
                  </Box>
                  <Box mb={2}>
                    <Typography variant="h6">Economy</Typography>
                    <Typography color="textSecondary">{`Main Export: ${economy.mainExport}`}</Typography>
                    <Typography color="textSecondary">{`Main Import: ${economy.mainImport}`}</Typography>
                    <Typography color="textSecondary">{`Main Resource: ${economy.mainResource}`}</Typography>
                    <Typography color="textSecondary">{`Trade: ${economy.trade}`}</Typography>
                    <Typography color="textSecondary">{`Strength: ${economy.strength}`}</Typography>
                    <Typography color="textSecondary">{`Wealth: ${economy.wealth}`}</Typography>
                  </Box>
                  <Box mb={2}>
                    <Typography variant="h6">Military</Typography>
                    <Typography color="textSecondary">{`Strength: ${military.strength}`}</Typography>
                    <Typography color="textSecondary">{`Focus: ${military.focus}`}</Typography>
                    <Typography color="textSecondary">{`Main Unit: ${military.mainUnit}`}</Typography>
                    <Typography color="textSecondary">{`Specialized Unit: ${military.specializedUnit}`}</Typography>
                    <Typography color="textSecondary">{`Soldiers: ${military.soldiers}`}</Typography>
                    <Typography color="textSecondary">{`Rank: ${military.rank}`}</Typography>
                  </Box>
                </Grid>
              </Grid>
              <HammerBreak />
            </Box>
          )
        )}
      </Box>
    </Box>
  )
}

export default Realms

import cuid from "cuid"
const helionne = {
  id: cuid(),
  name: `Aliak`,
  population: `Mixed`,
  ecology: {
    mainClimate: `temperate - jungle`,
    mountains: `some`,
    ocean: `none`,
    frequentTroubles: `hurricanes`,
    wilderness: `90%`,
    wildAnimals: `dangerous`,
    naturalResources: `abundant`,
  },
  politics: {
    politicalStructure: `true democracy`,
    strongInfluence: `military`,
    popularIssue: `ethics`,
    personalFreedoms: `very good`,
    scandals: `infrequent`,
    foreignRelations: `decent`,
  },
  culture: {
    highlyValues: `military prowess`,
    knownFor: `waterfalls`,
    popularEntertainment: `sports`,
    respectedProfession: `military officer`,
    discrimination: `virtually none`,
    majorTaboo: `necromancy`,
    majorSocialIll: `acceptance of the undead`,
    gastronomy: `Bananas, Avocados, Plantains, Iguana, Wild Boar, Cacao, Python, Deer, Squash, Chili peppers, Corn`,
  },
  religion: {
    type: `polytheism`,
    focus: `sin`,
    worship: `joyous prayer, mostly on holidays`,
    associatedArtform: `painting/murals`,
    prevelance: `believed by almost all`,
    holidays: `fairly often`,
  },
  economy: {
    mainExport: `food crops`,
    mainImport: `textiles`,
    mainResource: `food - crops/fruits`,
    trade: `imports and exports equal`,
    strength: `stable`,
    wealth: `fairly even, but with a wealth gap`,
  },
  military: {
    strength: `strong`,
    focus: `land`,
    mainUnit: `soldiers`,
    specializedUnit: `Panterium`,
    soldiers: `volunteers`,
    rank: `Pantera, Jaguar Knight, Leopard Knight, Shadow Knight, Shadow Rider, 1st LT, 2nd LT`,
  },
}

export default helionne
